// Hook the directive to the DOM element
const vRipple = {
  mounted: (el: HTMLElement, binding: {value: number}) => {
    // eslint-disable-next-line no-param-reassign
    el.style.position = 'relative';
    // eslint-disable-next-line no-param-reassign
    el.style.overflow = 'hidden';
    el.addEventListener('click', (ev) => {
      const rippleElement = document.createElement('span');
      let currentDiameter = 1;
      let currentOpacity = 0.65;

      const animationHandler = setInterval(() => {
        const maximalDiameter = +binding.value || 50;
        if (currentDiameter <= maximalDiameter) {
          currentDiameter += 2;
          currentOpacity -= 1 / maximalDiameter;
          rippleElement.style.transform = `scale(${currentDiameter})`;
          rippleElement.style.opacity = `${currentOpacity}`;
        } else {
          rippleElement.remove();
          clearInterval(animationHandler);
        }
      }, 15);

      const elementCoordinates = el.getBoundingClientRect();
      const offsetY = ev.clientY - elementCoordinates.y;
      const offsetX = ev.clientX - elementCoordinates.x;

      rippleElement.style.position = 'absolute';
      rippleElement.style.height = '5px';
      rippleElement.style.width = '5px';
      rippleElement.style.borderRadius = '100%';
      rippleElement.style.backgroundColor = '#f2f2f2';
      rippleElement.style.left = `${offsetX}px`;
      rippleElement.style.top = `${offsetY}px`;
      (el as HTMLElement).appendChild(rippleElement);
    });
  },
};

export default vRipple;
