import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/store/auth.store';

const authStore = useAuthStore();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('../views/Login/UnloggedLayout.vue'),
    meta: { pageTitle: 'Control' },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login/LoginView.vue'),
      }, {
        path: '/recover-password',
        name: 'RecoverPasswordRequest',
        component: () => import('../views/Login/RecoverPasswordRequest.vue'),
      }, {
        path: '/recover-password/:token',
        name: 'RecoverPasswordResult',
        component: () => import('../views/Login/RecoverPasswordResult.vue'),
        props: true,
      }, {
        path: '/account/password',
        name: 'ChangePassword',
        component: () => import('../views/Login/ChangePassword.vue'),
        props: (route) => route.query,
      },
    ],
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home/HomeView.vue'),
    meta: { pageTitle: 'Control', userAuth: true },
    redirect: { name: 'CompanyList' },
    children: [
      {
        path: '/company/list',
        name: 'CompanyList',
        component: () => import('../views/Company/CompanyList.vue'),
        props: (route) => route.query,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/company/:id/details',
        name: 'CompanyDetails',
        component: () => import('../views/Company/CompanyDetails.vue'),
        props: true,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/company/create',
        name: 'CompanyCreate',
        component: () => import('../views/Company/CompanyCreate.vue'),
        props: true,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/company/:id/update',
        name: 'CompanyUpdate',
        component: () => import('../views/Company/CompanyUpdate.vue'),
        props: true,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/staff',
        name: 'StaffList',
        component: () => import('../views/Staff/StaffList.vue'),
        props: true,
        meta: { auth: ['CA'] },
      }, {
        path: '/staff/create',
        name: 'StaffCreateByStaff',
        component: () => import('../views/Staff/StaffCreateByStaff.vue'),
        props: (router) => router.query,
        meta: { auth: ['CA'] },
      }, {
        path: '/staff/:id/update',
        name: 'StaffUpdateByStaff',
        component: () => import('../views/Staff/StaffUpdateByStaff.vue'),
        props: true,
        meta: { auth: ['CA'] },
      }, {
        path: '/user',
        name: 'UserList',
        component: () => import('../views/User/UserList.vue'),
        props: true,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/root/create',
        name: 'UserRootCreate',
        component: () => import('../views/Root/RootCreate.vue'),
        props: true,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/root/:id/update',
        name: 'UserRootUpdate',
        component: () => import('../views/Root/RootUpdate.vue'),
        props: true,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/root/createStaff',
        name: 'CompanyStaffCreateByRoot',
        component: () => import('../views/Root/StaffCreateByRoot.vue'),
        props: (router) => router.query,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/root/:id/updateStaff',
        name: 'CompanyStaffUpdateByRoot',
        component: () => import('../views/Root/StaffUpdateByRoot.vue'),
        props: true,
        meta: { auth: ['ROOT'] },
      }, {
        path: '/iotDevice/list',
        name: 'IoTDeviceList',
        component: () => import('../views/IoTDevice/IoTDeviceList.vue'),
        props: true,
        meta: { auth: ['CA', 'CS'] },
      }, {
        path: '/iotDevice/create',
        name: 'IoTDeviceCreate',
        component: () => import('../views/IoTDevice/IoTDeviceCreate.vue'),
        props: (router) => router.query,
        meta: { auth: ['CA'] },
      }, {
        path: '/iotDevice/:id/update',
        name: 'IoTDeviceUpdate',
        component: () => import('../views/IoTDevice/IoTDeviceUpdate.vue'),
        props: true,
        meta: { auth: ['CA'] },
      }, {
        path: '/iotDevice/:id/details',
        name: 'IoTDeviceDetails',
        component: () => import('../views/IoTDevice/IoTDeviceDetails.vue'),
        props: true,
        meta: { auth: ['CA', 'CS'] },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { currentUser } = authStore;
  const userRole = currentUser?.rbacs[0].role || '';
  const routeAuth = to.meta.auth as string[];

  if (!routeAuth && !currentUser) {
    next();
    return;
  } if (routeAuth && !currentUser) {
    next({ name: 'Login' });
    return;
  }
  if (currentUser && ((routeAuth && !routeAuth.includes(userRole)) || !routeAuth)) {
    if (userRole === 'CS') {
      next({ name: 'IoTDeviceList' });
      return;
    }
    if (userRole === 'CA') {
      next({ name: 'StaffList' });
      return;
    }
    if (userRole === 'ROOT') {
      next({ name: 'CompanyList' });
    }
  } else next();
});

router.afterEach((to) => {
  const pageTitle = to.meta.pageTitle as string | undefined;
  document.title = pageTitle ?? '';
  if (!to.meta.noScrollReset) window.scrollTo(0, 0);
});

export default router;
