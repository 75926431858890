// Vuetify
import 'vuetify/styles';
import { createVuetify, ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { useThemeStore } from '@/store/theme.store';
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import pinia from '@/store';

const themeStore = useThemeStore(pinia);

const controlTheme: ThemeDefinition = {
  colors: themeStore.colors,
};

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  },
  theme: {
    themes: {
      light: {
        colors: controlTheme.colors,
      }
    },
  },
});

export default vuetify;
