import { defineStore } from 'pinia';

export type HEX = `#${string}`;

export interface Colors {
  primary: HEX;
  secondary: HEX;
  gray: HEX;
  accent: HEX;
  [key: string]: HEX;
}

export type Color = keyof Colors | HEX | string;

export interface ThemeStoreType {
  currentColor: Colors,
}

function registerCssColors(color: Colors) {
  const style = document.createElement('style');
  style.type = 'text/css';
  document.getElementsByTagName('head')[0].appendChild(style);

  Object.keys(color).forEach((key) => {
    document.documentElement.style.setProperty(`--${key}`, color[key as keyof typeof color]);
    style.sheet?.insertRule(`.${key}{background-color: var(--${key})`, 0);
    style.sheet?.insertRule(`.text-${key}{color: var(--${key})`, 0);
  });
}


const defaultThemeStore: ThemeStoreType = {
  currentColor: {
    primary: '#44A64A',
    secondary: '#63F26D',
    gray: '#EEEEEE',
    accent: '#A64469',

    label: '#BBBBBB',
    error: '#F44336',
    success: '#4CAF50',

  },
};

registerCssColors(defaultThemeStore.currentColor);
export const useThemeStore = defineStore('theme', {
  state: (): ThemeStoreType => (defaultThemeStore),
  actions: {
    setColors(color: Colors) {
      this.currentColor = color;
      registerCssColors(color);
    },
  },
  getters: {
    colors(state) {
      return state.currentColor;
    },
  },
});

export default useThemeStore;
