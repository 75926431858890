import { defineStore } from 'pinia';
import {User} from "@/services/http/root.http";

interface CurrentUser extends User {
}
const localUser: CurrentUser | null = JSON.parse(localStorage.getItem('user') || 'null');
export const localToken = localStorage.getItem('token');

export interface UserState {
  currentUser: CurrentUser | null,
  token: string,
}
export const useAuthStore = defineStore('user', {
  state: (): UserState => ({
    currentUser: localUser || null,
    token: localToken || '',
  }),
  actions: {
    setUser(user: CurrentUser) {
      this.currentUser = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    removeUser() {
      this.currentUser = null;
      localStorage.removeItem('user');
    },
    setToken(token: string) {
      this.token = token;
      localStorage.setItem('token', token);
    },
    removeToken() {
      this.token = '';
      localStorage.removeItem('token');
    },
  },
  getters: {
    isAuthorized(state): boolean {
      return !!state.token;
    },
  },
});
