import { createApp } from 'vue';
import RippleJs from '@/plugins/ripple';
import vuetify from '@/plugins/vuetify';
import { vMaska } from 'maska';
import store from './store/index';
import App from './App.vue';

import router from './router/index';
import './services/http/index';
import 'bootstrap/dist/css/bootstrap-grid.css';

createApp(App)
  .directive('ripple', RippleJs)
  .directive('maska', vMaska)
  .use(vuetify)
  .use(router)
  .use(store)
  .mount('#app');
